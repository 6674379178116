import React, { useContext, useEffect } from 'react';
import "./MainBanner.css";

import { Waypoint } from 'react-waypoint';
import Search from '../search/Search';
import { RootStoreContext } from '../../stores/RootStoreContext';

interface Props {
  imageUrl: string;
  onSubmit?: (data: any) => void;
  scroll?: () => void;
  //   intlTitleId: string;
  //   intlDescriptionId: string;
}

export const MainBanner: React.FC<Props> = ({
  imageUrl,
  onSubmit,
  scroll
}) => {
  const context = useContext(RootStoreContext);
  const {
    state,
    setSticky,
    removeSticky,
    setDrawerState
  } = context.searchStore;

  const [scrollDisabled, setScrollDisabled] = React.useState(false);
  const [prevScrollY, setPrevScrollY] = React.useState(0);

  const onWaypointPositionChange = ({ currentPosition }: any) => {
    console.log(currentPosition);

    if (!currentPosition || currentPosition === 'above') {
      setDrawerState(false);
      setSticky();
    }
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > prevScrollY && prevScrollY === 0) {
      setScrollDisabled(true);
      if (scroll && !state.isSticky) {
        scroll();
      }
      setScrollDisabled(false);
    }

    setPrevScrollY(currentScrollY);
  }

  const checkScrollAtTop = () => {
    return window.scrollY === 0;
  };

  useEffect(() => {
    const disableScroll = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };


    // Add scroll event listener
    if (checkScrollAtTop())
      window.addEventListener('scroll', handleScroll);

    if (scrollDisabled) {
      // Prevent default scroll behavior
      window.addEventListener('wheel', disableScroll, { passive: false });
      window.addEventListener('touchmove', disableScroll, { passive: false });
    }

    return () => {
      // Cleanup event listeners when component unmounts
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', disableScroll);
      window.removeEventListener('touchmove', disableScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.scrollY, scrollDisabled]);

  return (
    <div className="bannerBox">
      <div className="bannerImg" style={{ backgroundImage: `url(${imageUrl})` }} />
      <div className="bannerContent">
        <div className="bannerTitle">
          {/* {window.scrollY} */}
          Make the right choices!
          {/* Boiler plate code for future localization updates */}
          {/* <FormattedMessage
            id={intlTitleId}
            defaultMessage="Set Your Title Through Language File"
            values={{ minute: 90 }}
          /> */}
        </div>
        <div className="bannerDescription">
          Find the best deals in Malta and Gozo
          {/* Boiler plate code for future localization updates */}
          {/* <FormattedMessage
            id={intlDescriptionId}
            defaultMessage="Set Your Description Through Language File"
          /> */}
        </div>
        <Search
          className="styledInput"
          placeholder={"What can we help you find?"}
          shadow="0 21px 36px rgba(0,0,0,0.05)"
          onSubmit={onSubmit}
          scroll={scroll}
          showAnimation={true}
        />
        <div style={{ height: "150px" }} />
        <Waypoint
          onEnter={removeSticky}
          onLeave={setSticky}
          onPositionChange={onWaypointPositionChange}
        />
      </div>
    </div>
  );
};
