import "./App.css";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes/allroutes";
import { observer } from "mobx-react-lite";
import ModalContainer from "./components/modal/ModalContainer";
import { ToastContainer, toast } from "react-toastify";
import { Fragment, useContext, useEffect } from "react";
import { RootStoreContext } from "./stores/RootStoreContext";
import { useMediaQuery } from "react-responsive";
import CookieConsent from "react-cookie-consent";
import ConfirmationModal from "./features/confirmationModal/ConfirmationModal";
import SideBarMobile from "./features/mainMenu/SideBarMobile";
import WindowFocusHandler from "./components/WindowFocusHandler";
import { isAndroid, isIOS } from "react-device-detect";
import DownloadAppButton from "./features/mobileStores/DownloadAppButton";
import ErrorBoundary from "./components/ErrorBoundary";
import AllClientOrderBubbles from "./features/clientOrders/AllClientOrderBubbles";
import HeaderMainMenu from "./features/headerMenu/HeaderMainMenu";
import SavingsBubble from "./features/savingsBubble/SavingsBubble";
import agent from "./api/agent";
import { format, isToday, isTomorrow } from "date-fns";
import { Localities } from "./options/Localities";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  let element = useRoutes(routes);
  const context = useContext(RootStoreContext);
  const { dispose } = context;
  const { intialize } = context.analyticsStore;
  const { getUser, isAdmin, accountId } = context.userStore;
  const { loadCart, getShoppingCartCost } = context.shoppingCartStore;
  const { isLoggedIn } = context.sessionStore;
  const { setScreenType, setIsPortrait, setUpDevice } = context.deviceStore;
  const { loadCache, savingsOpen } = context.cacheStore;
  const { getWalletBalance } = context.clientCreditStore;
  const { openModal } = context.modalStore;
  const { initialize_global_config } = context.globalConfig;
  const { CreateHubConnection, disposeSignalR } = context.signalRStore;
  const { getUserDietProfile } = context.userDietProfileStore;

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const dontShowCart = ["/my-profile",
    "/stabbilta",
    "/join-us",
    "/my-credits",
    "/about"].includes(location.pathname);

  const get_next_date_delivery = (date: Date) => {
    return `${date.toLocaleDateString('en-US', { weekday: 'long' })}`
  }

  const show_next_delivery = () => {
    const { user, isAdmin, accountId } = context.userStore;
    if (!isAdmin && user?.localityId && !accountId) {
      agent.Scheduler.get((new Date().getTime() / 1000), user.localityId, []).then((result) => {
        if ((result?.length ?? 0) === 0) return;

        var slot = result[0]
        toast(
          <div> Next available delivery for {Localities.filter(f => f.value === user.localityId)[0].description}: {" "}
            {isToday(new Date(slot.from * 1000)) ? "Today" : (isTomorrow(new Date(slot.from * 1000)) ? "Tomorrow" : get_next_date_delivery(new Date(slot.from * 1000)))}
            {" "} from {" "} {`${format(new Date(slot.from * 1000), 'H')} to ${format(new Date(slot.to * 1000), 'HH')} h`}
          </div>, {
          className: "next-delivery-slot",
          position: "bottom-left",
          autoClose: false,
          closeButton: true,
          draggable: true,
          onClick: () => {
            if (getShoppingCartCost > 0)
              navigate("/my-shopping-list");
          }
        })
      });
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const ref = urlParams.get('ref');

    if (ref) {
      localStorage.setItem('ref', ref);
    }

    loadCache();
    initialize_global_config();
    if (isLoggedIn) {
      getUser()
        .then(() => getWalletBalance())
        .then(() => getUserDietProfile())
        .then(() => CreateHubConnection());

      setTimeout(show_next_delivery, 1500);
      loadCart();
    }

    intialize();

    if (isMobile) setScreenType("mobile");
    else if (isTablet) setScreenType("tablet");
    else setScreenType("desktop");

    setUpDevice(isAndroid, isIOS);

    var clicked = window.localStorage.getItem("app-popup");
    if ((isAndroid || isIOS) && !isAdmin && (clicked === null || clicked !== "0"))
      openModal(<DownloadAppButton />, "small", "", false, false);

    setIsPortrait(isPortrait);

    return () => {
      disposeSignalR();
      dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intialize,
    setScreenType,
    isMobile,
    isTablet,
    isPortrait,
    initialize_global_config,
    isLoggedIn,
    getUser,
    dispose,
    setIsPortrait,
    loadCache,
  ]);

  return (
    <div>
      <WindowFocusHandler />
      <ModalContainer />
      <ConfirmationModal />
      {!dontShowCart && !isAdmin && !accountId && savingsOpen && <SavingsBubble />}
      <ToastContainer position="bottom-right" />
      <div id="mainContainer" className={`fxDirCol fxStretch`}>
        {isMobile ? <SideBarMobile element={element} /> : <HeaderMainMenu />}
        {!isMobile && (
          <Fragment>
            {/* <Divider
              style={{
                marginTop: "0px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            /> */}

            <div
              style={
                isMobile
                  ? { padding: "0" }
                  : {
                    // marginLeft: "10px",
                    // marginRight: "10px",
                    minHeight: "100vh",
                  }
              }
            >
              <ErrorBoundary>
                {element}
                {isLoggedIn && !isAdmin && <AllClientOrderBubbles />}
              </ErrorBoundary>
            </div>
          </Fragment>
        )}
      </div>
      <CookieConsent
        location="bottom"
        buttonText="I understand!"
        cookieName="cheapTrolleyCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div >
  );
}

export default observer(App);
