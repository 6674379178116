import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { IMongoPagedResponse, IMongoPagedWithSuggestionsResponse, IMonitorOrderResponse, ISqlPagedResponse } from "../interfaces/dynamoResponses";
import { stringify } from "querystring";
import { ISuperMarket } from "../interfaces/supermarket";
import { ICatalogueProductForCompare, IOrphanProductRelation, IProduct, IProductDetail, IProductForAdminUpdate, IProductForDescription, IProductForUpdate, IProductForWizard, ITrackerProductDetail, ITrackerProductForList } from "../interfaces/product";
import { IAddress, IShopUserForUpdate, IUserAccess, IUserAddressForLocality, IUserConfig, IUserForInvitation, IUserForLogin, IUserForRegistration, IUserMinimal, IUserPasswordResetValues, IUserProfile, IUserRoles } from "../interfaces/user";
import { IAccessToken } from "../interfaces/token";
import { IAssociation, IAssociationAnalysis, IAssociationAnalysisForUpdate, IAssociationForGlobalIntentoryExport, IAssociationForSave, IAssociationForView, IAssociationV2 } from "../interfaces/association";
import { IPossibleAssociation } from "../interfaces/possibleAssociation";
import { ISetupAddPanel } from "../interfaces/AdSetupPanel";
import { IOfferForCard } from "../interfaces/offer";
import { IAWSPreSignedPost } from "../interfaces/aws";
import { ISavings, IShoppingCartForView, IShoppingCartLoadResponse } from "../interfaces/shoping";
import { IShopForDetail, IShopForSave } from "../interfaces/shop";
import { IUploadProduct, IUploadProductResult } from "../interfaces/uploadInventory";
import { IShopForClaim } from "../interfaces/shopClaim";
import { IShopForIntegration } from "../interfaces/shopIntegration";
import { PaymentTransactionStatus } from "../options/PaymentTransactionStatus";
import { PaymentTransactionType } from "../options/PaymentTransactionType";
import { IBillingDetails, IModule, INewModuleForAssignment, IPricingDetails, IPricingDetailsMini } from "../interfaces/billing";
import { getErrorDescription } from "../helpers/errorHelper";
import { IPromoCodeForAdd, IPromoCodeForCheckout } from "../interfaces/promos";
import { ActiveOrder, IBoxes, IClientDetailsCourier, IClientProductReceiveUpdate, IHubOrder, IHubOrderForDetail, IHubOrderProductReceiveUpdate, IHubOrderProductReceiveUpdateForShop, IOrderDetailsCourier, IOrderForDetail, IOrderForList, IOrderForMonitor, IOrderToCreate, IOrderToValidate, IOrderTrackRecord, IOrderValidation, IOrdersToPrepare, IProposedHubOrderResponse, IShopOrderItemsForList, ITrackingRequestResponse } from "../interfaces/order";
import { IUserWallet } from "../interfaces/wallet";
import { IProductCategoryForCreate, ProductCategory, ProductCategoryForProcessing, ShopCategory, SmartProductCategory } from "../interfaces/categories";
import { IMeasurement, IMeasurementForCreate } from "../interfaces/measurements";
import { IHubs } from "../interfaces/hubs";
import { IFeeConfig } from "../interfaces/fees";
import { LabelStat, LabelStatForUpdate } from "../interfaces/labelStats";
import { IAddressResultIQ } from "../interfaces/locationIQ";
import { ILocation, ILocationForDropDown, IShopLocation } from "../interfaces/locations";
import { IBrandForList, IBrandForSave } from "../interfaces/brand";
import { IAssociationImage, ICatalogueForList, ICatalogueForOrder, ICatalogueForUpdate } from "../interfaces/catalogue";
import { IDietaryCategory } from "../interfaces/dietaryCategory";
import { IEmailOptions, IEmailTemplateForCreateUpdate, IEmailTemplateForDetail } from "../interfaces/email";
import { DeliverySlotStatus, IDeliverySlot, IDeliverySlotForList, IProposedSlot } from "../interfaces/delivery";
import { IExtraItem, IVATCategory } from "../interfaces/vat";
import { ICompanyDetails } from "../interfaces/company";
import { ClientOrderStatusType } from "../options/OrderStatusOption";
import { WalletTransactionType } from "../options/WalletTransactionTypes";
import { SearchPost, SortByTypes, Suggestions } from "../interfaces/search";
import { Referral } from "../interfaces/referral";
import { IProductListForCreation, IProductListForListDto, IProductListForUpdate } from "../interfaces/productLists";
import { UserLastestRequests } from "../interfaces/userRequests";
import { IChefAIProductLookUp, IHabibaiResponse, IFeedback, IIngredientSearch, INewMessage } from "../interfaces/chat";
import { IDietProfile } from "../interfaces/dietprofile";

const integationEndPoint = process.env.REACT_APP_INTEGRATION_API;
const billingEndPoint = process.env.REACT_APP_BILLING_API;
const authenticationEndPoint = process.env.REACT_APP_USER_API;

axios.interceptors.request.use(
    (config: any) => {
        const token = window.localStorage.getItem("jwt");
        if (token) config.headers.Authorization = `${token}`;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

var lastResponseWas: number;

axios.interceptors.response.use(
    (config) => {
        const curResponse = new Date().getTime() / 1000;

        const token = window.localStorage.getItem("jwt");
        const token_r = window.localStorage.getItem("jwt_r");

        if (
            token &&
            token_r &&
            (config.config.url?.toLowerCase().indexOf("/auth/token") ?? 0) < 0
        ) {
            if (lastResponseWas == null) {
                lastResponseWas = curResponse;
                return config;
            } else if (curResponse - lastResponseWas < 180) return config;

            lastResponseWas = curResponse;

            Auth.refreshToken(token_r)
                .then((response) => {
                    window.localStorage.setItem(
                        "jwt",
                        response.token_type + " " + response.access_token
                    );
                    window.localStorage.setItem("jwt_r", response.refresh_token);
                })
                .catch((error) => console.log(error));

        }

        return config;
    },
    (error) => {
        console.log(error);

        if (error.message === "Network Error" && !error.response) {
            toast.error("It's not you it's us; if the error persists please let us know and we will try to resolve it as soon as possible.");
            // throw error;
        }

        const { status, data } = error.response;

        if (status === 417) {
            toast.error(data);
            // throw error;
        }

        if (status === 500) {
            toast.error("It's not you it's us; if the error persists please let us know and we will try to resolve it as soon as possible.");
            // throw error;
        }
        else if (status === 401) {
            toast.info("This page requires an account to see it. You can register for free today!");
            // throw error;
        }
        else if (status === 404) {
            toast.info("We did not manage to find what you are looking for. Are you sure you have access to that resource? ");
        }
        else {
            var errorDesc = getErrorDescription(status, error.response);
            if (errorDesc)
                toast.error(errorDesc);
            throw error;
        }
    }
);

const responseBody = (response: AxiosResponse) => {
    try {
        return response.data;
    } catch (error) {
        return undefined;
    }
};

const requests = {
    get: (url: string, config?: AxiosRequestConfig) =>
        axios.get(url, config).then(responseBody),
    post: (url: string, body: {}, config?: AxiosRequestConfig) =>
        axios.post(url, body, config).then(responseBody),
    put: (url: string, body: {}) =>
        axios.put(url, body).then(responseBody),
    patch: (url: string, body: {}) =>
        axios.patch(url, body).then(responseBody),
    del: (url: string, config?: AxiosRequestConfig) =>
        axios.delete(url, config).then(responseBody),
    head: (url: string, config?: AxiosRequestConfig) =>
        axios.head(url, config).then(responseBody),
    postForm: (url: string, file: Blob, details?: any) => {
        let formData = new FormData();
        formData.append("File", file);

        if (details)
            Object.keys(details).forEach((key) => {
                formData.append(key, details[key]);
            });

        return axios
            .post(url, formData, {
                headers: { "Content-type": "multipart/form-data" },
            })
            .then(responseBody);
    },
};

const Offers = {
    Order: {
        get: (searchText: string, selectedMarketIds: string[], orderByDrop: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/offers`, {
                params: {
                    searchText: searchText,
                    marketIds: selectedMarketIds,
                    orderBy: orderByDrop ? "drop" : "lastUpdated",
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    get: (searchText: string, selectedMarketIds: string[], orderByDrop: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/offers/mobile/v2`, {
            params: {
                searchText: searchText,
                marketIds: selectedMarketIds,
                orderBy: orderByDrop ? "drop" : "lastUpdated",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
}

const Company = {
    get: (countryId: number): Promise<ICompanyDetails> =>
        requests.get(`${billingEndPoint}/companies/${countryId}`),
}

const UserRequests = {
    get: (searchText?: string, dateFrom?: number, dateTo?: number, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/requests-tracker`, {
            params: {
                searchText: searchText ?? "",
                dateFrom: dateFrom ?? -1,
                dateTo: dateTo ?? -1,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get_latest: (): Promise<UserLastestRequests[]> =>
        requests.get(`${integationEndPoint}/requests-tracker/latest`),
}

const Reports = {
    Receipts: {
        get: (searchText?: string, dateFrom?: number, dateTo?: number, currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/reports/receipts`, {
                params: {
                    searchText: searchText ?? "",
                    dateFrom: dateFrom ?? -1,
                    dateTo: dateTo ?? -1,
                    currentPage: currentPage ?? 0,
                    exportAll: exportAll ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    VatSummary: {
        get: (countryId: number, dateFrom?: number, dateTo?: number, frdateFrom?: number, frdateTo?: number, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/reports/vat-summary`, {
                params: {
                    countryId: countryId,
                    dateFrom: dateFrom ?? -1,
                    dateTo: dateTo ?? -1,
                    frdateFrom: frdateFrom ?? -1,
                    frdateTo: frdateTo ?? -1,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    ItemizedSales: {
        get: (countryId: number, byClient: boolean, selectedLocations: string[], dateFrom?: number, dateTo?: number, frdateFrom?: number, frdateTo?: number, currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/reports/itemized-sales`, {
                params: {
                    countryId: countryId,
                    dateFrom: dateFrom ?? -1,
                    locations: selectedLocations,
                    dateTo: dateTo ?? -1,
                    currentPage: currentPage ?? 0,
                    exportAll: exportAll ?? 0,
                    byClient: byClient === true ? 1 : 0,
                    frdateFrom: frdateFrom ?? -1,
                    frdateTo: frdateTo ?? -1,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Profit: {
        get: (countryId: number, aggregateBy: "day" | "month" | "year", dateFrom?: number, dateTo?: number, frdateFrom?: number, frdateTo?: number, currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/reports/profit`, {
                params: {
                    countryId: countryId,
                    dateFrom: dateFrom ?? -1,
                    dateTo: dateTo ?? -1,
                    currentPage: currentPage ?? 0,
                    exportAll: exportAll ?? 0,
                    aggregateBy: aggregateBy,
                    frdateFrom: frdateFrom ?? -1,
                    frdateTo: frdateTo ?? -1,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Wallets: {
        Totals: {
            get: (currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
                requests.get(`${billingEndPoint}/reports/credit`, {
                    params: {
                        currentPage: currentPage ?? 0,
                        exportAll: exportAll ?? 0,
                    },
                    paramsSerializer: (params) => {
                        return stringify(params);
                    },
                }),
        },
        Details: {
            get: (userId?: string, dateFrom?: number, dateTo?: number, currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
                requests.get(`${billingEndPoint}/reports/credit-detail`, {
                    params: {
                        userId: userId ?? "",
                        dateFrom: dateFrom ?? -1,
                        dateTo: dateTo ?? -1,
                        currentPage: currentPage ?? 0,
                        exportAll: exportAll ?? 0,
                    },
                    paramsSerializer: (params) => {
                        return stringify(params);
                    },
                }),
        }

    }
}

const Products = {
    byWeight: {
        count: (): Promise<number> =>
            requests.get(`${integationEndPoint}/weightedProducts/count`),
        delete: (id: string): Promise<void> =>
            requests.del(`${integationEndPoint}/weightedProducts/${id}`),
        next: (associated?: boolean): Promise<IOrphanProductRelation> =>
            requests.get(`${integationEndPoint}/weightedProducts/next`, {
                params: {
                    associated: associated ?? true,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    orphans: {
        count: (): Promise<number> =>
            requests.get(`${integationEndPoint}/orphanProducts/count`),
        convert: (id: string, values: IAssociationForSave): Promise<void> =>
            requests.post(`${integationEndPoint}/orphanProducts/${id}/convert`, values),
        approveRelation: (id: string, relationId: string, isPossible: boolean): Promise<void> =>
            requests.post(`${integationEndPoint}/orphanProducts/${id}/relation/${relationId}?isPossible=${isPossible ? 1 : 0}`, {}),
        removeRelation: (id: string, relationId: string, isPossible: boolean): Promise<void> =>
            requests.del(`${integationEndPoint}/orphanProducts/${id}/relation/${relationId}?isPossible=${isPossible ? 1 : 0}`),
        delete: (id: string): Promise<void> =>
            requests.del(`${integationEndPoint}/orphanProducts/${id}`),
        next: (associated?: boolean): Promise<IOrphanProductRelation> =>
            requests.get(`${integationEndPoint}/orphanProducts/next`, {
                params: {
                    associated: associated ?? true,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    save: (id: String, product: IProductForAdminUpdate): Promise<void> =>
        requests.patch(`${integationEndPoint}/products/${id}`, product),
    unverifiedNext: (verified: boolean, searchText: string, categoryIds: string[], selectedMarketIds: string[], selectedReviewIds: string[], discontinued: boolean, id?: string): Promise<IProductDetail> =>
        requests.get(`${integationEndPoint}/products/unverified_next`, {
            params: {
                searchText: searchText,
                categories: categoryIds,
                marketIds: selectedMarketIds,
                reviewIds: selectedReviewIds,
                discontinued: discontinued === false ? null : discontinued,
                id: id ?? null,
                verified: verified === false ? null : verified
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getUnverifiedCount: (verified: boolean, categoryIds: string[], selectedMarketIds: string[], selectedReviewIds: string[], discontinued: boolean): Promise<number> =>
        requests.get(`${integationEndPoint}/products/unverified_count`, {
            params: {
                categories: categoryIds,
                marketIds: selectedMarketIds,
                reviewIds: selectedReviewIds,
                discontinued: discontinued === false ? null : discontinued,
                verified: verified === false ? null : verified
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get: (searchText: string, categoryIds: string[], selectedMarketIds: string[], orderCheaperst: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/products`, {
            params: {
                searchText: searchText,
                categories: categoryIds,
                marketIds: selectedMarketIds,
                orderBy: orderCheaperst ? "currentPrice" : "itemDesc",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getCount: (unassociated: boolean, selectedMarketIds: string[], discontinued: boolean): Promise<number> =>
        requests.get(`${integationEndPoint}/products/count`, {
            params: {
                unassociated: unassociated,
                marketIds: selectedMarketIds,
                discontinued: discontinued === false ? null : discontinued
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getDetail: (id: string): Promise<IProduct> =>
        requests.get(`${integationEndPoint}/products/${id}`),
    getUnassociated: (searchText: string, discontinued: boolean, selectedMarketIds: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/products/unassociated`, {
            params: {
                searchText: searchText,
                currentPage: currentPage ?? 0,
                marketIds: selectedMarketIds,
                discontinued: discontinued === false ? null : discontinued
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getList: (ids: string[]): Promise<IProduct[]> =>
        requests.post(`${integationEndPoint}/products/list`, { keys: ids }),
    getMinimalList: (ids: string[]): Promise<IProductForDescription[]> =>
        requests.post(`${integationEndPoint}/products/minimal/list`, { keys: ids }),
    next: (marketId?: string): Promise<IProductForWizard> =>
        requests.get(`${integationEndPoint}/products/next`, {
            params: {
                marketId: marketId ?? "",
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getOffer: (id: string): Promise<IOfferForCard> =>
        requests.get(`${integationEndPoint}/products/${id}/offer`),
}

const Supermarkets = {
    get: (): Promise<ISuperMarket[]> =>
        requests.get(`${integationEndPoint}/supermarkets`),
    getForAccount: (): Promise<ISuperMarket[]> =>
        requests.get(`${integationEndPoint}/supermarkets/account`),
    // branches: (): Promise<ISuperMarketBranch[]> =>
    //     requests.get(`${integationEndPoint}/supermarkets/branches`),
};

const LocationIQ = {
    getKey: (): Promise<string> =>
        requests.get(`${billingEndPoint}/parameters/locationIQ`),
    searchAddress: (address: string, key: string): Promise<IAddressResultIQ[]> => {
        const overdInst = axios.create();

        return overdInst.get(`https://eu1.locationiq.com/v1/search`, {
            params: {
                key: key,
                q: address,
                format: 'json'
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }).then(responseBody);
    },
};

const Users = {
    Admin: {
        get: (currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/admin-carts`, {
                params: {
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Address: {
        get: (userId: string): Promise<IAddress[]> =>
            requests.get(`${authenticationEndPoint}/Address/${userId}`),
        get_detail: (addressId: string): Promise<IAddress> =>
            requests.get(`${authenticationEndPoint}/Address/${addressId}/detail`),
        add: (address: IAddress): Promise<string> =>
            requests.post(`${authenticationEndPoint}/Address`, address),
        update: (address: IAddress): Promise<void> =>
            requests.put(`${authenticationEndPoint}/Address/${address.id}`, address),
        delete: (id: string): Promise<void> =>
            requests.del(`${authenticationEndPoint}/Address/${id}`),
        getMany: (userIds: string[]): Promise<IUserAddressForLocality[]> =>
            requests.get(`${authenticationEndPoint}/Address/many`, {
                params: {
                    userIds: userIds,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Manage: {
        request_password_reset: (userId: string): Promise<string> =>
            requests.get(`${authenticationEndPoint}/Auth/${userId}/password-reset-url`),
        permanent_delete: (userId: string): Promise<IUserRoles> =>
            requests.del(`${authenticationEndPoint}/User/admin/${userId}/permanent`),
        get_roles: (userId: string): Promise<IUserRoles> =>
            requests.get(`${integationEndPoint}/user-roles/${userId}`),
        update_roles: (userId: string, roles: IUserRoles): Promise<any> =>
            requests.patch(`${integationEndPoint}/user-roles/${userId}`, roles),
        get_access: (userId: string): Promise<IUserAccess> =>
            requests.get(`${authenticationEndPoint}/User/${userId}/access`),
        toggleAdmin: (userId: string): Promise<void> =>
            requests.patch(`${authenticationEndPoint}/User/${userId}/isAdmin`, {}),
        toggleOwner: (userId: string): Promise<void> =>
            requests.patch(`${authenticationEndPoint}/User/${userId}/isOwner`, {}),
        lock: (userId: string): Promise<void> =>
            requests.patch(`${authenticationEndPoint}/User/lock/${userId}`, {}),
        unlock: (userId: string): Promise<void> =>
            requests.patch(`${authenticationEndPoint}/User/unlock/${userId}`, {}),
    },
    Wallet: {
        get: (userId: string): Promise<IUserWallet> =>
            requests.get(`${billingEndPoint}/wallet/${userId}`),
        append: (userId: string, type: WalletTransactionType, amount: number, comment: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/wallet/${userId}/transactions`, {
                'amount': amount,
                'type': type,
                'comment': comment,
            }),
        getBalanceOnly: (userId: string): Promise<number> =>
            requests.get(`${billingEndPoint}/wallet/${userId}?balanceOnly=true`),
    },
    getMany: (userIds: string[]): Promise<IUserProfile[]> =>
        requests.get(`${authenticationEndPoint}/User/many`, {
            params: {
                userIds: userIds,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getCount: (): Promise<number> =>
        requests.get(`${authenticationEndPoint}/User/count`),
    getCurrent: (): Promise<IUserMinimal> =>
        requests.get(`${authenticationEndPoint}/User/current`),
    getProfile: (userId: string): Promise<IUserMinimal> =>
        requests.get(`${authenticationEndPoint}/User/${userId}`),
    update: (userId: string, userProfile: any): Promise<void> =>
        requests.put(`${authenticationEndPoint}/User/${userId}`, userProfile),
    get: (searchText?: string, locked?: boolean, admin?: boolean, owner?: boolean, currentPage?: number): Promise<ISqlPagedResponse> =>
        requests.get(`${authenticationEndPoint}/User`, {
            params: {
                searchText: searchText ?? '',
                locked: locked ?? false,
                admin: admin ?? false,
                owner: owner ?? false,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getPreferredStore: (userId: string): Promise<string[]> =>
        requests.get(`${integationEndPoint}/preferredStores/${userId}`),
    updatePreferredStore: (ids: string[], userId?: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/preferredStores`, { marketIds: ids, userId: userId }),
};

const Associations = {
    Analysis: {
        get: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/associations/quality`, {
                params: {
                    searchText: searchText,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        next: (): Promise<IAssociationAnalysis> =>
            requests.get(`${integationEndPoint}/associations/quality/next`),
        get_detail: (id: string): Promise<IAssociationAnalysis> =>
            requests.get(`${integationEndPoint}/associations/quality/${id}`),
        update: (id: string, item: IAssociationAnalysisForUpdate): Promise<void> =>
            requests.patch(`${integationEndPoint}/associations/quality/${id}`, item),
    },
    Order: {
        get: (searchText: string, marketIds: string[], categoryIds: string[], sortBy?: SortByTypes, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/compare/v2`, {
                params: {
                    searchText: searchText,
                    categories: categoryIds,
                    marketIds: marketIds,
                    sortBy: sortBy,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        request: (id: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/compare/${id}/request`, {}),
    },
    Unverified: {
        verify: (id: string, item: IAssociationForSave): Promise<void> =>
            requests.patch(`${integationEndPoint}/associations/verify/${id}`, item),
        next: (searchText?: string, associationId?: string): Promise<IAssociation> =>
            requests.get(`${integationEndPoint}/associations/next`, {
                params: {
                    searchText: searchText,
                    associationId: associationId,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        count: (searchText?: string, associationId?: string): Promise<number> =>
            requests.get(`${integationEndPoint}/associations/unverified/count`, {
                params: {
                    searchText: searchText,
                    associationId: associationId,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    merge: (originalId: string, receiverId: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/associations/${originalId}/merge/${receiverId}`, {}),
    remove_temporary: (originalId: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/associations/${originalId}/tmp`, {}),
    create: (item: IAssociationForSave): Promise<string> =>
        requests.post(`${integationEndPoint}/associations`, item),
    update: (id: string, item: IAssociationForSave): Promise<void> =>
        requests.put(`${integationEndPoint}/associations/${id}`, item),
    delete: (id: string): Promise<void> =>
        requests.del(`${integationEndPoint}/associations/${id}`),
    get: (searchText: string, discontinued: boolean, marketIds: string[], categoryIds: string[], associationId?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/associations`, {
            params: {
                searchText: searchText,
                discontinued: discontinued,
                marketIds: marketIds,
                categories: categoryIds,
                associationId: associationId ?? "",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getTotalPages: (searchText: string, discontinued: boolean, marketIds: string[], categoryIds: string[], associationId?: string, currentPage?: number): Promise<number> =>
        requests.get(`${integationEndPoint}/associations/count`, {
            params: {
                searchText: searchText,
                discontinued: discontinued,
                marketIds: marketIds,
                categories: categoryIds,
                associationId: associationId ?? "",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get_temporary: (searchText: string, marketIds: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/associations/temporary`, {
            params: {
                searchText: searchText,
                marketIds: marketIds,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),

    getDetail: (id: string): Promise<IAssociation> =>
        requests.get(`${integationEndPoint}/associations/${id}`),

    getDetailView: (id: string, discontinued: boolean): Promise<IAssociationForView> =>
        requests.get(`${integationEndPoint}/associations/${id}/view`, {
            params: {
                discontinued: discontinued,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    addToAssociation: (associationId: string, productId: string,): Promise<void> =>
        requests.patch(`${integationEndPoint}/associations/${associationId}/add`, { productId: productId }),

    addManyToAssociation: (associationId: string, productIds: string[],): Promise<void> =>
        requests.patch(`${integationEndPoint}/associations/${associationId}/addmany`, { productIds: productIds }),

    removeFromAssociation: (associationId: string, productId: string,): Promise<void> =>
        requests.patch(`${integationEndPoint}/associations/${associationId}/remove`, { productId: productId }),

};

const PossibleAssociations = {
    v2: {
        many: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/possibleAssociationsV2/many`, {
                params: {
                    searchText: searchText,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        count: (): Promise<number> =>
            requests.get(`${integationEndPoint}/possibleAssociationsV2/count`),
        delete: (id: string): Promise<void> =>
            requests.del(`${integationEndPoint}/possibleAssociationsV2/${id}`),
        approve: (associationId: string, item: IAssociationForSave): Promise<void> =>
            requests.post(`${integationEndPoint}/possibleAssociationsV2/${associationId}`, item),
        merge: (possibleAssociationId: string, associationId: string): Promise<void> =>
            requests.put(`${integationEndPoint}/possibleAssociationsV2/${possibleAssociationId}/merge/${associationId}`, {}),
        removeRelation: (associationId: string, productId: string): Promise<void> =>
            requests.del(`${integationEndPoint}/possibleAssociationsV2/${associationId}/relation/${productId}`),
        removeGroupRelation: (associationId: string, groupId: string): Promise<void> =>
            requests.del(`${integationEndPoint}/possibleAssociationsV2/${associationId}/grouprelation/${groupId}`),
        next: (associationId?: string, associated?: boolean): Promise<IAssociationV2> =>
            requests.get(`${integationEndPoint}/possibleAssociationsV2/next`, {
                params: {
                    associated: associated ?? true,
                    associationId: associationId ?? null
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    get: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/possibleAssociations`, {
            params: {
                searchText: searchText,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getDetail: (id: string): Promise<IPossibleAssociation> =>
        requests.get(`${integationEndPoint}/possibleAssociations/${id}`),
    delete: (id: string): Promise<void> =>
        requests.del(`${integationEndPoint}/possibleAssociations/${id}`),
    refresh: (id: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/possibleAssociations/${id}`, {}),
    addRelated: (id: string, associationId: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/possibleAssociations/${id}/related`, { 'associationId': associationId }),
    getCount: (): Promise<number> =>
        requests.get(`${integationEndPoint}/possibleAssociations/count`),
};

const Auth = {
    register: (user: IUserForRegistration): Promise<string> =>
        requests.post(`${authenticationEndPoint}/Auth`, user),
    activate: (userId: string, token: string): Promise<void> =>
        requests.patch(`${authenticationEndPoint}/Auth/${userId}`, { token: token }),
    login: (user: IUserForLogin): Promise<IAccessToken> =>
        requests.post(`${authenticationEndPoint}/Auth/login`, user),
    refreshToken: (tkn_r: string): Promise<any> =>
        requests.post(`${authenticationEndPoint}/Auth/token`, {
            refresh_token: tkn_r,
        }),
    requestPasswordReset: (email: string): Promise<void> =>
        requests.head(`${authenticationEndPoint}/Auth/requestPasswordReset/${email}`, {}),
    passwordReset: (userId: string, values: IUserPasswordResetValues): Promise<void> =>
        requests.put(`${authenticationEndPoint}/Auth/${userId}/passwordReset`, values),
    changePassword: (userId: string, changePasswordValues: any): Promise<any> =>
        requests.put(`${authenticationEndPoint}/Auth/${userId}/changePassword`, changePasswordValues),
    deleteAccount: (): Promise<void> =>
        requests.del(`${authenticationEndPoint}/User`),
    deleteUserAccount: (userId: string): Promise<void> =>
        requests.del(`${authenticationEndPoint}/User/admin/${userId}`),

};

const AdminAds = {
    create: (item: ISetupAddPanel): Promise<string> =>
        requests.post(`${integationEndPoint}/adminAds`, item),
    getDetail: (id: string): Promise<ISetupAddPanel> =>
        requests.get(`${integationEndPoint}/adminAds/${id}`),
    update: (item: ISetupAddPanel): Promise<void> =>
        requests.put(`${integationEndPoint}/adminAds/${item.id}`, item),
    get: (currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/adminAds`, {
            params: {
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getRequestsCount: (): Promise<number> =>
        requests.get(`${integationEndPoint}/adminAds/request`),
    getCampaigns: (statuses: number[], currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/adminAds/campaigns`, {
            params: {
                statuses: statuses,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    approve: (id: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/adminAds/${id}/status/2`, {}),
    complete: (id: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/adminAds/${id}/status/4`, {}),
    cancel: (id: string, values: any): Promise<void> =>
        requests.patch(`${integationEndPoint}/adminAds/${id}/status/5`, values),
}

// const Adverts = {
//     save: (item: ICreateUpdateAdvert): Promise<string> =>
//         requests.post(`${integationEndPoint}/adverts`, item),
//     get: (currentPage?: number): Promise<IMongoPagedResponse> =>
//         requests.get(`${integationEndPoint}/adverts`, {
//             params: {
//                 currentPage: currentPage ?? 0,
//             },
//             paramsSerializer: (params) => {
//                 return stringify(params);
//             },
//         }),
//     getDetail: (id: string): Promise<ICreateUpdateAdvert> =>
//         requests.get(`${integationEndPoint}/adverts/${id}`),
//     update: (id: string, item: ICreateUpdateAdvert): Promise<void> =>
//         requests.put(`${integationEndPoint}/adverts/${id}`, item),
//     submit: (id: string): Promise<void> =>
//         requests.patch(`${integationEndPoint}/adverts/${id}/submit`, {}),
//     cancel: (id: string, values: any): Promise<void> =>
//         requests.patch(`${integationEndPoint}/adverts/${id}/cancel`, values),
//     init: (): Promise<IAdvertForView[]> =>
//         requests.get(`${integationEndPoint}/adverts/init`),
//     view: (id: string, frame: number): Promise<void> =>
//         requests.head(`${integationEndPoint}/adverts/${id}/view/${frame}`),
//     performance: (id: string): Promise<IAdvertForPerformance> =>
//         requests.get(`${integationEndPoint}/adverts/${id}/performance`),
//     click: (id: string, place: "inline" | "side"): Promise<void> =>
//         requests.head(`${integationEndPoint}/adverts/${id}/click`, {
//             'headers': {
//                 'ad-place': place
//             }
//         }),
// }

const Referrals = {
    Reports: {
        get: (shopId: string, aggregateBy: "day" | "month" | "year", dateFrom?: number, dateTo?: number, currentPage?: number, exportAll?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/referralSales`, {
                params: {
                    dateFrom: dateFrom ?? -1,
                    dateTo: dateTo ?? -1,
                    currentPage: currentPage ?? 0,
                    exportAll: exportAll ?? 0,
                    aggregateBy: aggregateBy,
                    shopId: shopId,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    getAll: (): Promise<Referral[]> =>
        requests.get(`${integationEndPoint}/referral`),
    create: (values: any): Promise<string> =>
        requests.post(`${integationEndPoint}/referral`, values),
}

const Cart = {
    V2: {
        calculate_savings: (userId?: string | null): Promise<ISavings> =>
            requests.get(`${integationEndPoint}/cart/savings`, {
                params: {
                    userId: userId ?? "",
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        get: (userId?: string | null): Promise<IShoppingCartLoadResponse> =>
            requests.get(`${billingEndPoint}/cart/v2`, {
                params: {
                    userId: userId ?? "",
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        update: (productId: string, quantity: number, userId: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/cart/${userId}`, { productId: productId, quantity: quantity }),
        empty: (userId: string): Promise<void> =>
            requests.del(`${billingEndPoint}/cart/${userId}`),
    },
    add: (productId: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/cart/add`, { productId: productId }),
    remove: (productId: string, all: boolean = false): Promise<void> =>
        requests.patch(`${integationEndPoint}/cart/remove`, { productId: productId, delete: all }),
    empty: (marketId: string): Promise<void> =>
        requests.del(`${integationEndPoint}/cart`, {
            params: {
                marketId: marketId,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get: (): Promise<IShoppingCartForView> =>
        requests.get(`${integationEndPoint}/cart`),
}

const Labels = {
    get: (searchText: string, reviewed: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/labels`, {
            params: {
                searchText: searchText,
                reviewed: reviewed === false ? null : reviewed,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    count: (): Promise<number> =>
        requests.get(`${integationEndPoint}/labels/count`),
    update: (id: string, item: LabelStatForUpdate): Promise<void> =>
        requests.patch(`${integationEndPoint}/labels/${id}`, item),
    group: (ids: string[]): Promise<void> =>
        requests.post(`${integationEndPoint}/labels/group`, {
            'groups': ids
        }),
    next: (reviewed: boolean): Promise<LabelStat> =>
        requests.get(`${integationEndPoint}/labels/next`, {
            params: {
                reviewed: reviewed === false ? null : reviewed,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
};

const LastSync = {
    get: (currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/lastSync`, {
            params: {
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    delete: (id: string): Promise<string> =>
        requests.del(`${integationEndPoint}/lastSync/${id}`),
};

const Brands = {
    get_all: (): Promise<IBrandForList[]> =>
        requests.get(`${integationEndPoint}/user-brands`),
    get_dropdown: (searchText?: string, ids?: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${billingEndPoint}/brands/dropdown`, {
            params: {
                searchText: searchText ?? "",
                ids: ids ?? [],
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    Admin: {
        create: (details: IBrandForSave): Promise<string> =>
            requests.post(`${billingEndPoint}/brands`, details),
        get: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/brands`, {
                params: {
                    searchText: searchText,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        get_products: (id: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/brands/${id}/products/paged`, {
                params: {
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        get_sample: (id: string): Promise<string[]> =>
            requests.get(`${billingEndPoint}/brands/${id}/products`),
        merge: (originalId: string, targetId: string): Promise<string[]> =>
            requests.patch(`${billingEndPoint}/brands/${originalId}/merge/${targetId}`, {}),
        detail: (id: string): Promise<any> =>
            requests.get(`${billingEndPoint}/brands/${id}`),
        delete: (id: string): Promise<any> =>
            requests.del(`${billingEndPoint}/brands/${id}`),
        removeProduct: (productId: string, brandId: string): Promise<any> =>
            requests.del(`${billingEndPoint}/brands/${brandId}/remove/${productId}`),
        update: (brandId: string, details: IBrandForSave): Promise<void> =>
            requests.put(`${billingEndPoint}/brands/${brandId}`, details),
    }
};

const Stabbilta = {
    get: (searchText: string, categoryIds: string[], orderCheapest: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/stabbilta`, {
            params: {
                searchText: searchText,
                categories: categoryIds,
                orderBy: orderCheapest ? "price" : "title",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    updateAssociation: (associationId: string, stabbiltaId: string,): Promise<void> =>
        requests.patch(`${integationEndPoint}/stabbilta/${stabbiltaId}`, { associationId: associationId }),
    removeAssociation: (stabbiltaId: string,): Promise<void> =>
        requests.del(`${integationEndPoint}/stabbilta/${stabbiltaId}`),
    getComparison: (associationId: string): Promise<ICatalogueProductForCompare[]> =>
        requests.get(`${integationEndPoint}/stabbilta/${associationId}/compare`),
}

const Catalogue = {
    Order: {
        get: (values: SearchPost): Promise<IMongoPagedWithSuggestionsResponse> =>
            requests.post(`${integationEndPoint}/catalogue/v2`, values),
        get_brands: (values: SearchPost): Promise<string[]> =>
            requests.post(`${integationEndPoint}/catalogue/brands`, values),
        getComparison: (associationId: string): Promise<ICatalogueProductForCompare[]> =>
            requests.get(`${integationEndPoint}/catalogue/${associationId}/compare`),
        getByIds: (ids: string[]): Promise<ICatalogueForOrder[]> =>
            requests.post(`${integationEndPoint}/catalogue/ids`, { keys: ids }),
        getForChef: (ids: string[]): Promise<IChefAIProductLookUp> =>
            requests.post(`${integationEndPoint}/catalogue/chef`, { keys: ids }),
        fetchSearchBarSuggestions: (searchText: string): Promise<Suggestions> =>
            requests.get(`${integationEndPoint}/catalogue/search-bar`, {
                params: {
                    searchText: searchText
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),

    },
    Extras: {
        get: (countryId: number): Promise<IExtraItem[]> =>
            requests.get(`${billingEndPoint}/extras`, {
                params: {
                    countryId: countryId
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    VAT: {
        update: (details: IVATCategory): Promise<void> =>
            requests.put(`${billingEndPoint}/vat/${details.id!}`, details),
        create: (details: IVATCategory): Promise<void> =>
            requests.post(`${billingEndPoint}/vat`, details),
        get: (countryId: number): Promise<IVATCategory[]> =>
            requests.get(`${billingEndPoint}/vat`, {
                params: {
                    countryId: countryId
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Admin: {
        refreshSmartSeatch: (): Promise<void> =>
            requests.patch(`${billingEndPoint}/catalogue-admin/refresh-stats`, {}),
        toggleHasImage: (catalogueId: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/catalogue-admin/${catalogueId}/image`, {}),
        getImages: (associationId: string): Promise<IAssociationImage[]> =>
            requests.get(`${billingEndPoint}/catalogue-admin/${associationId}/images`),
        get: (mode: "list" | "bulk", searchText: string, active: boolean, unavailable: boolean, available: boolean,
            missingFields: string[], categoryIds: string[], associationId?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/catalogue-admin`, {
                params: {
                    mode: mode,
                    missing: missingFields,
                    searchText: searchText,
                    active: active,
                    unavailable: unavailable,
                    available: available,
                    categoryId: categoryIds,
                    associationId: associationId,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        get_replacement: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/catalogue-admin/replacement`, {
                params: {
                    searchText: searchText,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        bulkUpdate: (updates: ICatalogueForUpdate, searchText: string, active: boolean,
            missingFields: string[], unavailable: boolean, available: boolean, categoryIds: string[]): Promise<any> =>
            requests.post(`${billingEndPoint}/catalogue-admin/bulk-update`, updates, {
                params: {
                    searchText: searchText,
                    missing: missingFields,
                    active: active,
                    unavailable: unavailable,
                    available: available,
                    categoryId: categoryIds,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        update: (id: string, details: ICatalogueForUpdate): Promise<void> =>
            requests.put(`${billingEndPoint}/catalogue-admin/${id}`, details),
        makeAvailable: (id: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/catalogue-admin/${id}/make-available`, {}),
        makeUnavailable: (id: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/catalogue-admin/${id}/make-unavailable`, {}),
        refresh: (id: string): Promise<ICatalogueForList> =>
            requests.put(`${integationEndPoint}/associations/${id}/refresh`, {}),
        refreshData: (id: string): Promise<ICatalogueForList> =>
            requests.get(`${billingEndPoint}/catalogue-admin/${id}/refresh`, {}),
        detail: (id: string): Promise<any> =>
            requests.get(`${billingEndPoint}/catalogue-admin/${id}`),
    }
};

const Shop = {
    ProductLists: {
        get: (shopId: string, listId: string): Promise<IProductListForUpdate> =>
            requests.get(`${integationEndPoint}/product-lists/${shopId}/${listId}`),
        get_many: (shopId: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/product-lists/${shopId}`, {
                params: {
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        update: (shopId: string, details: IProductListForListDto): Promise<void> =>
            requests.put(`${integationEndPoint}/product-lists/${shopId}/${details.id}`, details),
        create: (shopId: string, details: IProductListForCreation): Promise<string> =>
            requests.post(`${integationEndPoint}/product-lists/${shopId}`, details),
        delete: (shopId: string, listId: string): Promise<void> =>
            requests.del(`${integationEndPoint}/product-lists/${shopId}/${listId}`),
        data: (
            listId: string,
            selectedMarketIds: string[],
            accountId?: string,
            discontinued?: boolean,
            dropSince?: number,
            currentPage?: number,
        ): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/product-lists/data`, {
                params: {
                    listId: listId,
                    accountId: accountId,
                    marketIds: selectedMarketIds,
                    discontinued: discontinued === false ? null : discontinued,
                    dropSince: dropSince ?? -1,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        export: (
            listId: string,
            selectedMarketIds: string[],
            accountId?: string,
            discontinued?: boolean,
            dropSince?: number,
        ): Promise<IAssociationForGlobalIntentoryExport[]> =>
            requests.get(`${integationEndPoint}/product-lists/export`, {
                params: {
                    listId: listId,
                    accountId: accountId,
                    marketIds: selectedMarketIds,
                    discontinued: discontinued === false ? null : discontinued,
                    dropSince: dropSince ?? -1,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    },
    Locations: {
        Admin: {
            all: (): Promise<IShopLocation[]> =>
                requests.get(`${billingEndPoint}/locations/all`),
        },
        all: (shopId: string): Promise<ILocationForDropDown[]> =>
            requests.get(`${billingEndPoint}/locations/${shopId}/all`),
        get: (shopId: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/locations/${shopId}`, {
                params: {
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        getDetail: (shopId: string, locationId: string): Promise<ILocation> =>
            requests.get(`${billingEndPoint}/locations/${shopId}/branch/${locationId}`),
        add: (accountId: string, details: ILocation): Promise<string> =>
            requests.post(`${billingEndPoint}/locations/${accountId}`, details),
        update: (accountId: string, details: ILocation): Promise<string> =>
            requests.put(`${billingEndPoint}/locations/${accountId}/branch/${details.id!}`, details),
    },
    Orders: {
        get: (shopId?: string, displayId?: string, statusIds?: string[], dateFrom?: number, dateTo?: number, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/shopOrders`, {
                params: {
                    accountId: shopId,
                    displayId: displayId ?? "",
                    dateFrom: dateFrom ?? -1,
                    dateTo: dateTo ?? -1,
                    status: statusIds ?? [],
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        getItems: (id: string): Promise<IShopOrderItemsForList[]> =>
            requests.get(`${billingEndPoint}/shopOrders/${id}/items`),
        update_item: (orderId: string, details: any): Promise<void> =>
            requests.put(`${billingEndPoint}/tabletHubOrders/${orderId}/item`, details),

    },
    Billing: {
        Modules: {
            active: (accountId: string): Promise<string[]> =>
                requests.get(`${billingEndPoint}/modules/${accountId}/active`),
            types: (): Promise<IModule[]> =>
                requests.get(`${billingEndPoint}/modules/all`),
            assign: (accountId: string, details: INewModuleForAssignment): Promise<string> =>
                requests.post(`${billingEndPoint}/modules/${accountId}`, details),
            get: (accountId: string, currentPage?: number): Promise<IMongoPagedResponse> =>
                requests.get(`${billingEndPoint}/modules/${accountId}`, {
                    params: {
                        currentPage: currentPage ?? 0,
                    },
                    paramsSerializer: (params) => {
                        return stringify(params);
                    },
                }),
        },
        Pricing: {
            get: (accountId: string): Promise<IPricingDetails> =>
                requests.get(`${integationEndPoint}/billing/pricing/${accountId}`),
            create: (accountId: string, details: IPricingDetails): Promise<any> =>
                requests.post(`${integationEndPoint}/billing/pricing/${accountId}`, details),
            update: (accountId: string, details: IPricingDetails): Promise<any> =>
                requests.put(`${integationEndPoint}/billing/pricing/${accountId}`, details),
            get_list: (): Promise<IPricingDetailsMini[]> =>
                requests.get(`${integationEndPoint}/billing/pricing/all`),

        },
        get: (accountId: string): Promise<IBillingDetails> =>
            requests.get(`${integationEndPoint}/billing/${accountId}`),
        create: (accountId: string, details: IBillingDetails): Promise<any> =>
            requests.post(`${integationEndPoint}/billing/${accountId}`, details),
        update: (accountId: string, details: IBillingDetails): Promise<any> =>
            requests.patch(`${integationEndPoint}/billing/${accountId}`, details),
        getPaymentTransactions: (
            accountId: string,
            statuses: PaymentTransactionStatus[],
            types: PaymentTransactionType[],
            startDate: Date | null,
            endDate: Date | null,
            lastId: string | null,
            limit: number
        ): Promise<IMongoPagedResponse> =>
            requests.get("/Billing/transactions", {
                params: {
                    AccountId: accountId,
                    Status: statuses,
                    Types: types,
                    StartDate: startDate === null ? null : startDate.getTime() / 1000,
                    EndDate: endDate === null ? null : endDate.getTime() / 1000,
                    LastId: lastId,
                    Limit: limit,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        downloadInvoice: (invoiceNumber: string): Promise<string> =>
            requests.get(`/Billing/invoice/${invoiceNumber}/download`, {
                responseType: "blob",
            }),
    },
    Manage: {
        get_access: (accountId: string): Promise<string[]> =>
            requests.get(`${integationEndPoint}/supermarkets/${accountId}/access`),
        set_access: (accountId: string, shops: string[]): Promise<void> =>
            requests.patch(`${integationEndPoint}/supermarkets/${accountId}/access`, shops),
        getUsers: (accountId?: string, currentPage?: number): Promise<ISqlPagedResponse> =>
            requests.get(`${authenticationEndPoint}/shopuser`, {
                params: {
                    accountId: accountId,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        invite: (user: IUserForInvitation): Promise<string> =>
            requests.post(`${authenticationEndPoint}/shopuser/invite`, user),
        updateUser: (userId: string, user: IShopUserForUpdate): Promise<string> =>
            requests.patch(`${authenticationEndPoint}/shopuser/${userId}`, user),
        unlinkUser: (userId: string): Promise<string> =>
            requests.patch(`${authenticationEndPoint}/shopuser/${userId}/unlink`, {}),
        deleteUser: (userId: string): Promise<string> =>
            requests.del(`${authenticationEndPoint}/shopuser/${userId}`),
    },
    Integration: {
        get: (statuses: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/integration`, {
                params: {
                    statuses: statuses,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        getCount: (): Promise<number> =>
            requests.get(`${integationEndPoint}/integration/count`),
        request: (item: IShopForIntegration): Promise<string> =>
            requests.post(`${integationEndPoint}/integration`, item),
        getMine: (): Promise<IShopForIntegration> =>
            requests.get(`${integationEndPoint}/integration/mine`),
        reject: (requestId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/integration/reject/${requestId}`, {}),
        approve: (requestId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/integration/approve/${requestId}`, {}),
    },
    Claims: {
        get: (statuses: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/claim`, {
                params: {
                    statuses: statuses,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        getCount: (): Promise<number> =>
            requests.get(`${integationEndPoint}/claim/count`),
        request: (item: IShopForClaim): Promise<string> =>
            requests.post(`${integationEndPoint}/claim`, item),
        getMine: (): Promise<IShopForClaim> =>
            requests.get(`${integationEndPoint}/claim/mine`),
        reject: (requestId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/claim/reject/${requestId}`, {}),
        approve: (requestId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/claim/approve/${requestId}`, {}),
    },
    Admin: {
        get: (shopId: string): Promise<IShopForDetail> =>
            requests.get(`${integationEndPoint}/supermarkets/${shopId}`),
        activate: (shopId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/supermarkets/${shopId}/activate`, {}),
        hide: (shopId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/supermarkets/${shopId}/hide`, {}),
        deactivate: (shopId: string): Promise<void> =>
            requests.patch(`${integationEndPoint}/supermarkets/${shopId}/deactivate`, {}),
    },
    getMany: (searchText: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/supermarkets/paged`, {
            params: {
                searchText: searchText,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getCount: (): Promise<number> =>
        requests.get(`${integationEndPoint}/supermarkets/count`),
    create: (item: IShopForSave): Promise<string> =>
        requests.post(`${integationEndPoint}/supermarkets`, item),
    requestActivation: (): Promise<void> =>
        requests.put(`${integationEndPoint}/supermarkets/request_activation`, {}),
    update: (item: IShopForSave): Promise<void> =>
        requests.put(`${integationEndPoint}/supermarkets`, item),
    updateLogo: (fileName: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/supermarkets`, {
            imageUrl: fileName
        }),
    get: (): Promise<IShopForDetail> =>
        requests.get(`${integationEndPoint}/supermarkets/mine`),
}

const GlobalInventory = {
    get: (
        selectedSearchType: "any" | "contain" | "starts",
        searchText: string,
        skus: string,
        marketIds: string[],
        discontinued?: boolean,
        internal?: boolean,
        dropSince?: number,
        notMyPriceDrops?: boolean,
        min?: number,
        max?: number,
        currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/global_inventory`, {
            params: {
                type: selectedSearchType,
                searchText: searchText,
                skus: skus,
                marketIds: marketIds,
                currentPage: currentPage ?? 0,
                dropSince: dropSince ?? -1,
                discontinued: discontinued === false ? null : discontinued,
                internal: internal === false ? null : internal,
                exlMyDrops: notMyPriceDrops === false ? null : notMyPriceDrops,
                min: min ?? "",
                max: max ?? "",
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    export: (
        selectedSearchType: "any" | "contain" | "starts",
        searchText: string,
        selectedMarketIds: string[],
        accountId?: string,
        discontinued?: boolean,
        internal?: boolean,
        dropSince?: number,
        notMyPriceDrops?: boolean,
        min?: number,
        max?: number,
    ): Promise<IAssociationForGlobalIntentoryExport[]> =>
        requests.get(`${integationEndPoint}/global_inventory/export`, {
            params: {
                type: selectedSearchType,
                accountId: accountId,
                searchText: searchText,
                marketIds: selectedMarketIds,
                discontinued: discontinued === false ? null : discontinued,
                internal: internal === false ? null : internal,
                dropSince: dropSince ?? -1,
                exlMyDrops: notMyPriceDrops === false ? null : notMyPriceDrops,
                min: min ?? "",
                max: max ?? "",
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
}

const PriceTracker = {
    get: (searchText: string, categoryIds: string[], selectedMarketIds: string[], groupedOnly: boolean, nonDelivery: boolean, accountId?: string, discontinued?: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/tracker`, {
            params: {
                accountId: accountId,
                searchText: searchText,
                categories: categoryIds,
                marketIds: selectedMarketIds,
                currentPage: currentPage ?? 0,
                groupedOnly: groupedOnly,
                nonDelivery: nonDelivery,
                discontinued: discontinued === false ? null : discontinued
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    export: (searchText: string, categoryIds: string[], selectedMarketIds: string[], accountId?: string, discontinued?: boolean): Promise<ITrackerProductForList[]> =>
        requests.get(`${integationEndPoint}/tracker/export`, {
            params: {
                accountId: accountId,
                searchText: searchText,
                categories: categoryIds,
                marketIds: selectedMarketIds,
                discontinued: discontinued === false ? null : discontinued
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getDetail: (id: string): Promise<ITrackerProductDetail[]> =>
        requests.get(`${integationEndPoint}/tracker/${id}`),
}

const Inventory = {
    upload: (accountId: string, items: IUploadProduct[], locationId?: string): Promise<IUploadProductResult> =>
        requests.post(`${integationEndPoint}/inventory/upload`, {
            accountId: accountId,
            items: items,
            locationId: locationId
        }),
    get: (searchText: string, ungroupedOnly: boolean, activeOnly: boolean, accountId?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/inventory`, {
            params: {
                accountId: accountId,
                searchText: searchText,
                ungroupedOnly: ungroupedOnly,
                activeOnly: activeOnly,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    update: (id: string, item: IProductForUpdate): Promise<void> =>
        requests.patch(`${integationEndPoint}/inventory/${id}`, item),
    toggleDelivery: (id: string): Promise<boolean> =>
        requests.patch(`${integationEndPoint}/inventory/${id}/delivery`, {}),
    getDetail: (id: string): Promise<IProductForUpdate> =>
        requests.get(`${integationEndPoint}/inventory/${id}`),
}

const PromoCodes = {
    validate: (code: string): Promise<IPromoCodeForCheckout> =>
        requests.get(`${billingEndPoint}/promoCodes/${code}/check`),
    getForRetry: (code: string): Promise<IPromoCodeForCheckout> =>
        requests.get(`${billingEndPoint}/promoCodes/${code}/retry`),
    count: (activeOnly?: boolean): Promise<number> =>
        requests.get(`${billingEndPoint}/promoCodes/count`, {
            params: {
                activeOnly: activeOnly ?? false,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get: (currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${billingEndPoint}/promoCodes`, {
            params: {
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    create: (item: IPromoCodeForAdd): Promise<string> =>
        requests.post(`${billingEndPoint}/promoCodes`, item),
    update: (id: string, item: IPromoCodeForAdd): Promise<void> =>
        requests.put(`${billingEndPoint}/promoCodes/${id}`, item),
    disable: (id: string): Promise<void> =>
        requests.del(`${billingEndPoint}/promoCodes/${id}`),
}

const ProductCategories = {
    nest: (childId: string, parentId: string): Promise<void> =>
        requests.patch(`${integationEndPoint}/admin-categories/${childId}/nest/${parentId}`, {}),
    create: (value: IProductCategoryForCreate): Promise<string> =>
        requests.post(`${integationEndPoint}/admin-categories`, value),
    update: (value: ProductCategoryForProcessing): Promise<string> =>
        requests.put(`${integationEndPoint}/admin-categories/${value.id}`, value),
    getAdmin: (searchText?: string): Promise<ProductCategoryForProcessing[]> =>
        requests.get(`${integationEndPoint}/admin-categories/process`, {
            params: {
                searchText: searchText ?? "",
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getMain: (): Promise<ProductCategory[]> =>
        requests.get(`${integationEndPoint}/categories/main`),
    get_smart: (): Promise<SmartProductCategory[]> =>
        requests.get(`${integationEndPoint}/categories/online`),
    getShop: (accountId?: string): Promise<ShopCategory[]> =>
        requests.get(`${integationEndPoint}/categories/shop`, {
            params: {
                accountId: accountId,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getAll: (): Promise<ProductCategory[]> =>
        requests.get(`${integationEndPoint}/admin-categories/all`),
}

const ProductMeasurements = {
    create: (value: IMeasurementForCreate): Promise<string> =>
        requests.post(`${integationEndPoint}/measurements`, value),
    get: (): Promise<IMeasurement[]> =>
        requests.get(`${integationEndPoint}/measurements`),
}

const DietaryCategories = {
    create: (value: IDietaryCategory): Promise<string> =>
        requests.post(`${integationEndPoint}/dietaryCategories`, value),
    get: (): Promise<IDietaryCategory[]> =>
        requests.get(`${integationEndPoint}/dietaryCategories`),
}

const HubOrders = {
    get: (statusIds: string[]): Promise<IHubOrder[]> =>
        requests.get(`${integationEndPoint}/hubOrders`, {
            params: {
                statusIds: statusIds ?? [],
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    get_detail: (orderId: string): Promise<IHubOrderForDetail> =>
        requests.get(`${integationEndPoint}/hubOrders/${orderId}`),
    replace: (orderId: string, values: any): Promise<void> =>
        requests.patch(`${integationEndPoint}/hubOrders/${orderId}/replacement`, values),
    Receive: {
        update: (orderId: string, status: "received" | "completed"): Promise<void> =>
            requests.patch(`${integationEndPoint}/hubOrders/${orderId}/status`, { status: status }),
        updateItem: (orderId: string, productId: string, values: IHubOrderProductReceiveUpdate): Promise<void> =>
            requests.patch(`${integationEndPoint}/hubOrders/${orderId}/products/${productId}`, values),
        updateItemForShop: (orderId: string, productId: string, values: IHubOrderProductReceiveUpdateForShop): Promise<void> =>
            requests.patch(`${integationEndPoint}/hubOrders/${orderId}/products/${productId}/shop`, values),
    },
    Prepare: {
        get: (): Promise<IOrdersToPrepare> =>
            requests.get(`${integationEndPoint}/hubOrders/client`),
        get_proposed: (): Promise<IProposedHubOrderResponse> =>
            requests.get(`${integationEndPoint}/hubOrders/proposed`),
        create: (orderIds: string[]): Promise<void> =>
            requests.post(`${integationEndPoint}/hubOrders`, { 'orderIds': orderIds }),
    }
}

const Orders = {
    Admin: {
        Monitor: {
            setPaymentType: (orderId: string, paymentType: string): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/payment`, {
                    paidBy: paymentType
                }),
            accept: (orderId: string): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/accept`, {}),
            delete: (orderId: string): Promise<void> =>
                requests.del(`${integationEndPoint}/order-monitor/${orderId}`),
            get: (statusIds?: string[], currentPage?: number): Promise<IMonitorOrderResponse> =>
                requests.get(`${integationEndPoint}/order-monitor`, {
                    params: {
                        statusIds: statusIds ?? [],
                        currentPage: currentPage ?? 0,
                    },
                    paramsSerializer: (params) => {
                        return stringify(params);
                    },
                }),
            update: (orderId: string, status: ClientOrderStatusType, paymentType?: string): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}`, {
                    status: status,
                    paidBy: paymentType
                }),
            reprint: (orderId: string): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/reprint`, {}),
            get_detail: (orderId: string): Promise<IOrderForMonitor> =>
                requests.get(`${integationEndPoint}/order-monitor/${orderId}`),
            updateItem: (orderId: string, locationId: string, productId: string, values: IClientProductReceiveUpdate): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/locations/${locationId}/products/${productId}`, values),
            updatePrinted: (orderId: string): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/print`, {}),
            refundItem: (orderId: string, locationId: string, productId: string, quantity: number): Promise<void> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/locations/${locationId}/products/${productId}/refund`, { quantity: quantity }),
            requestTracking: (orderId: string, boxes: IBoxes, clientDetails: IClientDetailsCourier, orderDetails: IOrderDetailsCourier): Promise<ITrackingRequestResponse> =>
                requests.patch(`${integationEndPoint}/order-monitor/${orderId}/request-tracking`, {
                    boxes: boxes,
                    clientDetails: clientDetails,
                    orderDetails: orderDetails
                }),
        },
        reschedule: (id: string, newSlotId: string): Promise<IDeliverySlotForList> =>
            requests.patch(`${billingEndPoint}/orders/${id}/reschedule`, {
                deliverySlotId: newSlotId
            }),
        retry: (id: string): Promise<void> =>
            requests.patch(`${billingEndPoint}/orders/${id}/adminRetry`, {}),
    },
    download_receipt: (orderId: string): Promise<any> =>
        requests.get(`${billingEndPoint}/orders/${orderId}/download-receipt`),
    trackOrder: (orderId: string): Promise<IOrderTrackRecord[]> =>
        requests.get(`${integationEndPoint}/order-monitor/${orderId}/track-order`),
    refresh: (id: string): Promise<IOrderForList> =>
        requests.get(`${billingEndPoint}/orders/${id}/list`),
    create: (item: IOrderToCreate): Promise<string> =>
        requests.post(`${billingEndPoint}/orders`, item),
    validate: (item: IOrderToValidate): Promise<IOrderValidation> =>
        requests.post(`${billingEndPoint}/orders/validate`, item),
    retry: (id: string): Promise<string> =>
        requests.put(`${billingEndPoint}/orders/${id}/retry`, {}),
    get: (searchText: string, statusIds?: string[], currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${billingEndPoint}/orders`, {
            params: {
                searchText: searchText,
                statusIds: statusIds ?? [],
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getDetail: (id: string): Promise<IOrderForDetail> =>
        requests.get(`${billingEndPoint}/orders/${id}`),
    getDetailByPaymentId: (id: string): Promise<IOrderForDetail> =>
        requests.get(`${billingEndPoint}/orders/payment/${id}`),
    updateOrderTransactionId: (orderId: string, trns: string): Promise<void> =>
        requests.patch(`${billingEndPoint}/orders/payment/${orderId}`, { 'transactionId': trns }),
    active: (): Promise<ActiveOrder[]> =>
        requests.get(`${integationEndPoint}/orders`),
    lastAddress: (): Promise<IAddress | undefined> =>
        requests.get(`${integationEndPoint}/orders/last-address`),
}

const Hubs = {
    get: (): Promise<IHubs[]> =>
        requests.get(`${integationEndPoint}/hubs`)
}

const Fees = {
    get: (): Promise<IFeeConfig[]> =>
        requests.get(`${billingEndPoint}/fees`)
}

const ExpressInterest = {
    EarlyBird: {
        request: (selectedRecurrence: string, selectedWay: string): Promise<string> =>
            requests.post(`${billingEndPoint}/interest`, {
                recurrence: selectedRecurrence,
                way: selectedWay
            }),
        get: (): Promise<boolean> =>
            requests.get(`${billingEndPoint}/interest`),
        count: (): Promise<number> =>
            requests.get(`${billingEndPoint}/interest/count`),
        get_list: (currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${billingEndPoint}/interest/list`, {
                params: {
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
    }
}

const Scheduler = {
    get: (ordertime: number, localityId: number, locations: string[]): Promise<IDeliverySlot[]> =>
        requests.get(`${billingEndPoint}/scheduler`, {
            params: {
                ordertime: ordertime,
                localityId: localityId,
                locations: locations
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    Slots: {
        prepare: (countryNumId: number): Promise<IProposedSlot[]> =>
            requests.get(`${integationEndPoint}/country/${countryNumId}/schedule/preview`),
        create: (items: IProposedSlot[]): Promise<void> =>
            requests.post(`${integationEndPoint}/country/schedule/create`, { slots: items }),
    },
    Deliveries: {
        get: (includeClosed: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/scheduler/slots`, {
                params: {
                    includeClosed: includeClosed,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        get_one: (id: string): Promise<IDeliverySlotForList> =>
            requests.get(`${integationEndPoint}/scheduler/slots/${id}`),
        refresh: (id: string): Promise<IDeliverySlotForList> =>
            requests.patch(`${integationEndPoint}/scheduler/slots/${id}/refresh`, {}),
        delete: (id: string): Promise<void> =>
            requests.del(`${integationEndPoint}/scheduler/slots/${id}`),
        update_status: (id: string, newStatus: DeliverySlotStatus): Promise<void> =>
            requests.patch(`${integationEndPoint}/scheduler/slots/${id}/status`, { 'status': newStatus }),
        close_past: (countryNumId: number): Promise<void> =>
            requests.patch(`${integationEndPoint}/scheduler/${countryNumId}/close`, {}),
    }
}

const MarketingCampaigns = {
    Users: {
        get: (campaignId: string, status?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/marketing-campaigns/${campaignId}/users`, {
                params: {
                    status: status ?? null,
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        add: (campaignId: string, userId: string): Promise<IMongoPagedResponse> =>
            requests.post(`${integationEndPoint}/marketing-campaigns/${campaignId}/users`, {
                'userId': userId
            }),
        remove: (campaignId: string, userId: string): Promise<IMongoPagedResponse> =>
            requests.del(`${integationEndPoint}/marketing-campaigns/${campaignId}/users/${userId}`),
        add_all: (campaignId: string): Promise<IMongoPagedResponse> =>
            requests.post(`${integationEndPoint}/marketing-campaigns/${campaignId}/users/all`, {}),
    },
    get: (searchText?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/marketing-campaigns`, {
            params: {
                searchText: searchText ?? "",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    stats: (campaignId: string): Promise<any> =>
        requests.get(`${integationEndPoint}/marketing-campaigns/${campaignId}/pre-send-emails-stats`),
    sendEmails: (campaignId: string): Promise<any> =>
        requests.get(`${integationEndPoint}/marketing-campaigns/${campaignId}/send-emails`),

    create: (emailId: string): Promise<string> =>
        requests.post(`${integationEndPoint}/marketing-campaigns`, { 'emailId': emailId }),
}

const Emails = {
    get: (searchText?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/emails`, {
            params: {
                searchText: searchText ?? "",
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getOptions: (): Promise<IEmailOptions[]> =>
        requests.get(`${integationEndPoint}/emails/options`),
    getDetail: (id: string): Promise<IEmailTemplateForDetail> =>
        requests.get(`${integationEndPoint}/emails/${id}`),
    create: (values: IEmailTemplateForCreateUpdate): Promise<string> =>
        requests.post(`${integationEndPoint}/emails`, values),
    delete: (id: string): Promise<void> =>
        requests.del(`${integationEndPoint}/emails/${id}`),
    update: (id: string, values: IEmailTemplateForCreateUpdate): Promise<string> =>
        requests.put(`${integationEndPoint}/emails/${id}`, values),
}

const GlobalConfig = {
    Countries: {
        get: (searchText?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
            requests.get(`${integationEndPoint}/country`, {
                params: {
                    searchText: searchText ?? "",
                    currentPage: currentPage ?? 0,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        Schedule: {
            get: (countryId: string): Promise<any> =>
                requests.get(`${integationEndPoint}/country/${countryId}/schedule`),
            update: (countryId: string, values: any): Promise<void> =>
                requests.patch(`${integationEndPoint}/country/${countryId}/schedule`, values),
        }
    },
    allowsDelivery: (countryId: number, localityId: number, lng: number, lat: number): Promise<boolean> =>
        requests.get(`${integationEndPoint}/config`, {
            params: {
                countryId: countryId,
                localityId: localityId,
                lng: lng,
                lat: lat,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    isDeliveryEnabled: (): Promise<boolean> =>
        requests.get(`${billingEndPoint}/global_config`, {
            params: {
                parameter: 'allowDelivery',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    isEarlyBirdEnabled: (): Promise<boolean> =>
        requests.get(`${billingEndPoint}/global_config`, {
            params: {
                parameter: 'earlyBird',
                global: 'true',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getSixPackCount: (): Promise<number> =>
        requests.get(`${billingEndPoint}/global_config`, {
            params: {
                parameter: 'sixPackAmount',
                global: 'true',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getBottleLimitCount: (): Promise<number> =>
        requests.get(`${billingEndPoint}/global_config`, {
            params: {
                parameter: 'singleBottles',
                global: 'true',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    isUserDeliveryEnabled: (userId: string): Promise<boolean> =>
        requests.get(`${billingEndPoint}/global_config/${userId}`, {
            params: {
                parameter: 'allowDelivery',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    isUserChefEnabled: (userId: string): Promise<boolean> =>
        requests.get(`${billingEndPoint}/global_config/${userId}`, {
            params: {
                parameter: 'chefAI',
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    isBulkUserDeliveryEnabled: (userIds: string[]): Promise<IUserConfig[]> =>
        requests.post(`${billingEndPoint}/global_config/many`, {
            parameter: 'allowDelivery',
            userIds: userIds
        }),
    updateDeliveryEnabled: (userId: string, value: boolean): Promise<boolean> =>
        requests.put(`${billingEndPoint}/global_config/${userId}`,
            {
                parameter: 'allowDelivery',
                value: value,
            }),
    updateChefAI: (userId: string, value: boolean): Promise<boolean> =>
        requests.put(`${billingEndPoint}/global_config/${userId}`,
            {
                parameter: 'chefAI',
                value: value,
            }),
    getConfig: (param: string, global: boolean): Promise<any> =>
        requests.get(`${billingEndPoint}/global_config`, {
            params: {
                parameter: param,
                global: global,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    updateGlobalConfig: (param: string, values: any): Promise<any> =>
        requests.put(`${billingEndPoint}/global_config/global`, {
            parameter: param,
            value: values,
        }),
}

const Devices = {
    get: (searchText?: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/devices`, {
            params: {
                searchText: searchText,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
}

const Habibai = {
    DietProfiles: {
        get: (id: string): Promise<IDietProfile> =>
            requests.get(`${integationEndPoint}/diet-profile/${id}`),
        create: (profile: IDietProfile): Promise<string> =>
            requests.post(`${integationEndPoint}/diet-profile`, profile),
        update: (profile: IDietProfile): Promise<void> =>
            requests.patch(`${integationEndPoint}/diet-profile`, profile),
    },
    getChats: (adminMode: boolean, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/chat`, {
            params: {
                adminMode: adminMode,
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    getMessages: (chatId: string, currentPage?: number): Promise<IMongoPagedResponse> =>
        requests.get(`${integationEndPoint}/chat/${chatId}/messages`, {
            params: {
                currentPage: currentPage ?? 0,
            },
            paramsSerializer: (params) => {
                return stringify(params);
            },
        }),
    sendMessage: (message: INewMessage): Promise<IHabibaiResponse> =>
        requests.post(`${integationEndPoint}/chat`, message),
    searchIngredient: (
        chatId: string,
        messageId: string,
        recipeId: string,
        ingredientId: string,
        message: IIngredientSearch
    ): Promise<IHabibaiResponse> =>
        requests.patch(`${integationEndPoint}/chat/${chatId}/messages/${messageId}/${recipeId}/ingredient/${ingredientId}`, message),
    giveFeedback: (
        chatId: string,
        messageId: string,
        feedback: IFeedback,
    ): Promise<void> =>
        requests.patch(`${integationEndPoint}/chat/${chatId}/messages/${messageId}/rate`, feedback),
}

const AWS = {
    Bucket: {
        analyze: (url: string): Promise<any> =>
            requests.get(`${integationEndPoint}/files/analyze`, {
                params: {
                    url: url,
                },
                paramsSerializer: (params) => {
                    return stringify(params);
                },
            }),
        createPresignedUrl: (fileName: string): Promise<IAWSPreSignedPost> =>
            requests.get(`${integationEndPoint}/files/${fileName}`),
        getFileUrl: (fileName: string): Promise<string> =>
            requests.get(`${integationEndPoint}/files/${fileName}/url`),
        delete: (fileName: string): Promise<void> =>
            requests.del(`${integationEndPoint}/files/${fileName}`),
        uploadFile: (file: Blob, info: IAWSPreSignedPost): Promise<any> => {
            const uninterceptedAxiosInstance = axios.create();
            let formData = new FormData();

            if (info)
                Object.keys(info.fields).forEach((key) => {
                    formData.append(key, (info.fields as any)[key]);
                });

            formData.append("File", file);

            return uninterceptedAxiosInstance
                .post(info.url, formData, {
                    headers: { "Content-type": "multipart/form-data" },
                })
                .then(responseBody);
        },
    }
}

const publicObject = {
    Supermarkets,
    Products,
    Associations,
    Users,
    Auth,
    PossibleAssociations,
    AdminAds,
    Offers,
    // Adverts,
    Cart,
    Shop,
    AWS,
    Inventory,
    PromoCodes,
    Orders,
    ProductCategories,
    ProductMeasurements,
    Hubs,
    Fees,
    Labels,
    PriceTracker,
    LastSync,
    GlobalInventory,
    GlobalConfig,
    LocationIQ,
    Catalogue,
    Brands,
    ExpressInterest,
    DietaryCategories,
    Emails,
    Scheduler,
    Reports,
    Company,
    Stabbilta,
    HubOrders,
    MarketingCampaigns,
    Referrals,
    UserRequests,
    Devices,
    Habibai
};

export default publicObject;