import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Image } from 'semantic-ui-react';
import { IMessage } from '../../../interfaces/chat';
import ProductsMessage from './ProductsMessage';
import RecipeMessage from './RecipeMessage';
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from 'react-device-detect';

interface IProps {
    // conversationId?: string;
}
const ChatMessages: React.FC<IProps> = () => {
    const messageEndRef = useRef<HTMLDivElement | null>(null);

    const context = useContext(RootStoreContext);
    const {
        messages,
        loadMessages,
        hasMoreMessages,
        loadingMessages,
        conversationId
    } = context.chatUIStore;

    const scrollToBottom = () => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollTop = messageEndRef.current.scrollHeight - 200;
        }
    };

    useEffect(() => {
        scrollToBottom();

        return () => {

        }
    }, [])


    const renderMessage = (message: IMessage) => {
        var content = message.content;
        if (typeof content === 'string') {
            return content;
        } else if (typeof content === 'object' && content !== null && !Array.isArray(content)) {
            if (content.recipes.length > 0)
                return <RecipeMessage key={message.msg_id} messageId={message.msg_id} message={content} scroll={scrollToBottom} status={message.status} />
            else if (content.products.length > 0)
                return <ProductsMessage key={message.msg_id} msg_id={message.msg_id} message={content} scroll={scrollToBottom} status={message.status} />;
            else {
                return content.message;
            }
        }
    }
    return (
        <div
            id="chat-messages-div"
            className='chat-messages fxDisplay fxDirCol'
            ref={messageEndRef}>
            {/* {messages.length === 0 && loadingMessages && <div className='ct-green-text-dark' style={{ textAlign: "center" }}>
                <Icon name='circle notch' loading size='big' />
                <p style={{ paddingTop: "5px" }}>We are loading your conversation....</p>
            </div>} */}
            <InfiniteScroll
                style={{ overflow: "hidden", display: 'flex', flexDirection: 'column-reverse' }}
                dataLength={messages.length}
                next={() => loadMessages(conversationId!)}
                hasMore={hasMoreMessages}
                loader={loadingMessages ? <div /> : <div />}
                inverse={true}
                scrollableTarget="chat-messages-div"
            >
                {messages.map((message) => (
                    <div
                        key={message.sentOn}
                        className={message.sender === 'user' ? "user-message" : "assistant-message"}
                    >
                        {!isMobile && message.sender === "assistant" &&
                            <Image
                                src="/trolley-cone.svg"
                                style={
                                    {
                                        width: "32px",
                                        height: "32px"
                                    }}
                            />}
                        <div className={
                            message.sender === 'user' ? "message-bubble" : "message-bubble-assistant typewriter"}
                            style={isMobile ? { maxWidth: "80%" } : { maxWidth: "60%" }}
                        >
                            <p>
                                {renderMessage(message)}
                            </p>
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
        </div>
    )
}

export default observer(ChatMessages)